exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-cases-tsx": () => import("./../../../src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-purple-teaming-tsx": () => import("./../../../src/pages/purple-teaming.tsx" /* webpackChunkName: "component---src-pages-purple-teaming-tsx" */),
  "component---src-pages-suomeksi-tsx": () => import("./../../../src/pages/suomeksi.tsx" /* webpackChunkName: "component---src-pages-suomeksi-tsx" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-anna-koskinen-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/anna-koskinen.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-anna-koskinen-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-antti-seitovirta-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/antti-seitovirta.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-antti-seitovirta-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-henri-lindberg-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/henri-lindberg.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-henri-lindberg-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-jani-kallio-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/jani-kallio.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-jani-kallio-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-jorma-keinanen-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/jorma-keinanen.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-jorma-keinanen-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-juho-mitrunen-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/juho-mitrunen.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-juho-mitrunen-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-knud-hojgaard-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/knud-hojgaard.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-knud-hojgaard-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-ludvig-lund-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/ludvig-lund.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-ludvig-lund-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-marko-leino-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/marko-leino.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-marko-leino-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-michal-bubilek-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/michal-bubilek.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-michal-bubilek-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-mika-tolvanen-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/mika-tolvanen.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-mika-tolvanen-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-mikkel-boholdt-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/mikkel-boholdt.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-mikkel-boholdt-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-mirko-schicchi-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/mirko-schicchi.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-mirko-schicchi-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-rayyan-hassan-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/rayyan-hassan.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-rayyan-hassan-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-saverio-turetta-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/saverio-turetta.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-saverio-turetta-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-tommi-laukkanen-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/tommi-laukkanen.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-tommi-laukkanen-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-toni-huttunen-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/toni-huttunen.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-toni-huttunen-md" */),
  "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-tuomo-makkonen-md": () => import("./../../../src/templates/people.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/people/tuomo-makkonen.md" /* webpackChunkName: "component---src-templates-people-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-people-tuomo-makkonen-md" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-connected-device-maker-secure-sdlc-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/connected-device-maker-secure-sdlc.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-connected-device-maker-secure-sdlc-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-continuous-detection-and-response-testing-for-critical-infrastructure-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/continuous-detection-and-response-testing-for-critical-infrastructure.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-continuous-detection-and-response-testing-for-critical-infrastructure-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-developing-cyber-security-maturity-with-kybermittari-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/developing-cyber-security-maturity-with-kybermittari.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-developing-cyber-security-maturity-with-kybermittari-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-device-maker-hardware-security-assessment-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/device-maker-hardware-security-assessment.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-device-maker-hardware-security-assessment-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-digital-forensics-for-compromised-companies-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/digital-forensics-for-compromised-companies.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-digital-forensics-for-compromised-companies-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-elisa-cyber-security-controls-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/elisa-cyber-security-controls.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-elisa-cyber-security-controls-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-energy-company-data-analytics-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/energy-company-data-analytics.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-energy-company-data-analytics-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-financial-entity-zero-trust-architecture-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/financial-entity-zero-trust-architecture.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-financial-entity-zero-trust-architecture-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-iot-maker-sonarqube-training-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/iot-maker-sonarqube-training.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-iot-maker-sonarqube-training-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-public-authority-data-analytics-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/public-authority-data-analytics.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-public-authority-data-analytics-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-purple-teaming-for-property-management-company-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/purple-teaming-for-property-management-company.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-purple-teaming-for-property-management-company-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-space-company-osint-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/space-company-osint.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-space-company-osint-mdx" */),
  "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-web-app-security-assessment-for-wellness-company-mdx": () => import("./../../../src/templates/work.tsx?__contentFilePath=/home/runner/work/www.fraktal.fi/www.fraktal.fi/content/case/web-app-security-assessment-for-wellness-company.mdx" /* webpackChunkName: "component---src-templates-work-tsx-content-file-path-home-runner-work-www-fraktal-fi-www-fraktal-fi-content-case-web-app-security-assessment-for-wellness-company-mdx" */)
}

